export class HandledError<T> extends Error {
    code: T;
    params?: Record<string, any>;

    constructor(code: T, message: string, params?: Record<string, any>) {
        super(message);
        this.code = code;
        this.params = params;
    }
}
